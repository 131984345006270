<template>
	<section class="content-main">
		<!--基本信息-->
		<el-form :model="form" label-width="120px" :rules="formRules" ref="form">

			<el-form-item label="姓名" prop="true_name">
				<el-input v-model.trim="form.true_name" class="short-width"></el-input>
			</el-form-item>
			<el-form-item label="角色组" prop="role_id">
				<el-checkbox-group v-model="form.role_id">
					<el-checkbox  v-for="i in roleOption" :label="i.id" :key="i.id">{{i.name}}</el-checkbox>
				</el-checkbox-group>
			</el-form-item>

			<el-form-item label="账号" prop="user_name">
				<el-input v-model="form.user_name" placeholder="输入手机号码作为登录ID" class="short-width" :maxlength="11"></el-input>
			</el-form-item>

			<el-form-item label="密码" prop="password">
				<el-input type="password" v-model.trim="form.password" :maxlength="15" placeholder="输入6-15位数字+字母组合做为密码" class="short-width"></el-input>
			</el-form-item>

			<el-form-item label="备注">
				<el-input v-model.trim="form.remark"></el-input>
			</el-form-item>
			<el-form-item label=" ">
				<el-button v-if="!isEdit" type="primary" @click.native="addSubmit" :loading="Loading" icon="el-icon-circle-check">提交</el-button>
				<el-button v-else type="primary" @click.native="saveSubmit" :loading="Loading" icon="el-icon-circle-check">保存</el-button>
				<el-button @click="goBack" icon="el-icon-circle-close">取消</el-button>
			</el-form-item>

		</el-form>

	</section>
</template>

<script>

	import {saveAdmin, addAdmin, getUploadToken, uploadFiles, viewAdmin, getAdminRoleList} from '../../api/api';

	export default {
		data() {
            //验证电话号码格式
            var validatePhone = (rule, value, callback) => {
                if(value === ''){
                    callback(new Error('手机号码不能为空'));
                }else if(!(/^1[3456789]\d{9}$/.test(value))) {
                    callback(new Error('请输入正确的手机号码格式'));
                } else {
                    callback();
                }
            };
			return {
				//编辑ID
				aID: this.$route.query.id,
				isEdit: false,	//是否为编辑
				//表单验证规则
				formRules: {
					user_name: [
                        { required: true, validator: validatePhone, trigger: 'blur' },
					],
					true_name: [
						{ required: true, message: '请输入姓名', trigger: 'blur' }
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' },
						{ min: 6, max: 15, message: '长度在6到15个字符', trigger: 'blur' }
					],
					role_id: [
						{ required: true, type:'array', message: '请选择角色组', trigger: 'change' }
					]
				},
				roleOption: [],	//角色组选项

				//创建新增
				Loading: false,
				//新增界面数据
				form: {
					true_name: '',
					group_id: '1',
					user_name: '',
					password: '',
					remark: '',
					role_id: []
				},
			}
		},
		methods: {
			//获取角色组列表
			getList() {
				let para = {
					status: 1,
					pagesize: 999	//分页条数
				};
				getAdminRoleList(para).then((res) => {
					if(res.code == 1){
						this.roleOption = res.data.data;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//新增
			addSubmit: function () {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.$confirm('确认提交吗？', '提示', {}).then(() => {
							this.Loading = true;
							let para = Object.assign({}, this.form);
							para.role_id = para.role_id.toString()
							addAdmin(para).then((res) => {
								this.Loading = false;
								if(res.code==1){
									this.$message({
										message: res.msg,
										type: 'success'
									});
									this.goBack();
								}else {
									this.$message({
										message: res.msg,
										type: 'error'
									});
								}
							});
						});
					}
				});
			},
			//获取信息
			getInfo() {
				let para = {id: this.aID};
				viewAdmin(para).then((res) => {
					this.form = Object.assign({}, res.data);
					//密码为6个*
					this.form.password = '******'
					//赋值角色组
					let a = res.data.role_id.split(',').filter(i => {
						if(i != 0) return i
					})
                    this.form.role_id = []
                    for(var i of a){
                        this.form.role_id.push(+i)
					}
				});
			},
			//编辑保存
			saveSubmit: function () {
			    //判断是否有不存在的数组ID
                let a = this.roleOption.map(i => i.id), b = this.form.role_id
				for(var i of b){
                    if(a.indexOf(i) < 0){b
                        b.splice(b.indexOf(i), 1)
					}
				}
				//提交表单验证
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.$confirm('确认提交吗？', '提示', {}).then(() => {
							this.Loading = true;
							//若密码无变动则发送空值
							if(this.form.password=='******'){
								this.form.password = '';
							}
							let para = Object.assign({}, this.form);
							para.role_id = para.role_id.toString()
							saveAdmin(para).then((res) => {
								this.Loading = false;
								if(res.code==1){
									this.$message({
										message: res.msg,
										type: 'success'
									});
									this.goBack();
								}else {
									this.$message({
										message: res.msg,
										type: 'error'
									});
								}
							});
						});
					}
				});
			},
		},
		mounted() {
			//判断是否为编辑页并获取文章信息
			if(this.aID){
				this.isEdit = true
				this.getInfo()
			}

			//获取角色组列表
			this.getList()

		}
	}

</script>